// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-bidra-alle-kan-bidra-mdx": () => import("./../../../src/pages/bidra/alle-kan-bidra.mdx" /* webpackChunkName: "component---src-pages-bidra-alle-kan-bidra-mdx" */),
  "component---src-pages-bidra-guide-for-figma-mdx": () => import("./../../../src/pages/bidra/guide-for-figma.mdx" /* webpackChunkName: "component---src-pages-bidra-guide-for-figma-mdx" */),
  "component---src-pages-bidra-guide-til-dokumentering-mdx": () => import("./../../../src/pages/bidra/guide-til-dokumentering.mdx" /* webpackChunkName: "component---src-pages-bidra-guide-til-dokumentering-mdx" */),
  "component---src-pages-bidra-guide-til-github-mdx": () => import("./../../../src/pages/bidra/guide-til-github.mdx" /* webpackChunkName: "component---src-pages-bidra-guide-til-github-mdx" */),
  "component---src-pages-bidra-kom-med-losningsforslag-mdx": () => import("./../../../src/pages/bidra/kom-med-losningsforslag.mdx" /* webpackChunkName: "component---src-pages-bidra-kom-med-losningsforslag-mdx" */),
  "component---src-pages-bidra-kom-med-onske-mdx": () => import("./../../../src/pages/bidra/kom-med-onske.mdx" /* webpackChunkName: "component---src-pages-bidra-kom-med-onske-mdx" */),
  "component---src-pages-bidra-meld-inn-feil-mdx": () => import("./../../../src/pages/bidra/meld-inn-feil.mdx" /* webpackChunkName: "component---src-pages-bidra-meld-inn-feil-mdx" */),
  "component---src-pages-faq-mdx": () => import("./../../../src/pages/faq.mdx" /* webpackChunkName: "component---src-pages-faq-mdx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kom-i-gang-mdx": () => import("./../../../src/pages/kom-i-gang.mdx" /* webpackChunkName: "component---src-pages-kom-i-gang-mdx" */),
  "component---src-pages-komponenter-accordion-mdx": () => import("./../../../src/pages/komponenter/accordion.mdx" /* webpackChunkName: "component---src-pages-komponenter-accordion-mdx" */),
  "component---src-pages-komponenter-collapse-mdx": () => import("./../../../src/pages/komponenter/collapse.mdx" /* webpackChunkName: "component---src-pages-komponenter-collapse-mdx" */),
  "component---src-pages-komponenter-diagram-mdx": () => import("./../../../src/pages/komponenter/diagram.mdx" /* webpackChunkName: "component---src-pages-komponenter-diagram-mdx" */),
  "component---src-pages-komponenter-dropdown-mdx": () => import("./../../../src/pages/komponenter/dropdown.mdx" /* webpackChunkName: "component---src-pages-komponenter-dropdown-mdx" */),
  "component---src-pages-komponenter-faner-mdx": () => import("./../../../src/pages/komponenter/faner.mdx" /* webpackChunkName: "component---src-pages-komponenter-faner-mdx" */),
  "component---src-pages-komponenter-feedback-mdx": () => import("./../../../src/pages/komponenter/feedback.mdx" /* webpackChunkName: "component---src-pages-komponenter-feedback-mdx" */),
  "component---src-pages-komponenter-formatering-mdx": () => import("./../../../src/pages/komponenter/formatering.mdx" /* webpackChunkName: "component---src-pages-komponenter-formatering-mdx" */),
  "component---src-pages-komponenter-grid-mdx": () => import("./../../../src/pages/komponenter/grid.mdx" /* webpackChunkName: "component---src-pages-komponenter-grid-mdx" */),
  "component---src-pages-komponenter-header-mdx": () => import("./../../../src/pages/komponenter/header.mdx" /* webpackChunkName: "component---src-pages-komponenter-header-mdx" */),
  "component---src-pages-komponenter-ikoner-mdx": () => import("./../../../src/pages/komponenter/ikoner.mdx" /* webpackChunkName: "component---src-pages-komponenter-ikoner-mdx" */),
  "component---src-pages-komponenter-knapper-mdx": () => import("./../../../src/pages/komponenter/knapper.mdx" /* webpackChunkName: "component---src-pages-komponenter-knapper-mdx" */),
  "component---src-pages-komponenter-kontovelger-mdx": () => import("./../../../src/pages/komponenter/kontovelger.mdx" /* webpackChunkName: "component---src-pages-komponenter-kontovelger-mdx" */),
  "component---src-pages-komponenter-kort-mdx": () => import("./../../../src/pages/komponenter/kort.mdx" /* webpackChunkName: "component---src-pages-komponenter-kort-mdx" */),
  "component---src-pages-komponenter-lister-mdx": () => import("./../../../src/pages/komponenter/lister.mdx" /* webpackChunkName: "component---src-pages-komponenter-lister-mdx" */),
  "component---src-pages-komponenter-meldinger-mdx": () => import("./../../../src/pages/komponenter/meldinger.mdx" /* webpackChunkName: "component---src-pages-komponenter-meldinger-mdx" */),
  "component---src-pages-komponenter-modal-mdx": () => import("./../../../src/pages/komponenter/modal.mdx" /* webpackChunkName: "component---src-pages-komponenter-modal-mdx" */),
  "component---src-pages-komponenter-paginering-mdx": () => import("./../../../src/pages/komponenter/paginering.mdx" /* webpackChunkName: "component---src-pages-komponenter-paginering-mdx" */),
  "component---src-pages-komponenter-skjemaelementer-mdx": () => import("./../../../src/pages/komponenter/skjemaelementer.mdx" /* webpackChunkName: "component---src-pages-komponenter-skjemaelementer-mdx" */),
  "component---src-pages-komponenter-spinners-mdx": () => import("./../../../src/pages/komponenter/spinners.mdx" /* webpackChunkName: "component---src-pages-komponenter-spinners-mdx" */),
  "component---src-pages-komponenter-tabeller-mdx": () => import("./../../../src/pages/komponenter/tabeller.mdx" /* webpackChunkName: "component---src-pages-komponenter-tabeller-mdx" */),
  "component---src-pages-komponenter-typografi-mdx": () => import("./../../../src/pages/komponenter/typografi.mdx" /* webpackChunkName: "component---src-pages-komponenter-typografi-mdx" */),
  "component---src-pages-komponenter-wave-mdx": () => import("./../../../src/pages/komponenter/wave.mdx" /* webpackChunkName: "component---src-pages-komponenter-wave-mdx" */),
  "component---src-pages-om-designsystemet-prinsipper-mdx": () => import("./../../../src/pages/om-designsystemet/prinsipper.mdx" /* webpackChunkName: "component---src-pages-om-designsystemet-prinsipper-mdx" */),
  "component---src-pages-profil-animasjoner-mdx": () => import("./../../../src/pages/profil/animasjoner.mdx" /* webpackChunkName: "component---src-pages-profil-animasjoner-mdx" */),
  "component---src-pages-profil-bilder-mdx": () => import("./../../../src/pages/profil/bilder.mdx" /* webpackChunkName: "component---src-pages-profil-bilder-mdx" */),
  "component---src-pages-profil-farger-mdx": () => import("./../../../src/pages/profil/farger.mdx" /* webpackChunkName: "component---src-pages-profil-farger-mdx" */),
  "component---src-pages-profil-former-mdx": () => import("./../../../src/pages/profil/former.mdx" /* webpackChunkName: "component---src-pages-profil-former-mdx" */),
  "component---src-pages-profil-ikoner-mdx": () => import("./../../../src/pages/profil/ikoner.mdx" /* webpackChunkName: "component---src-pages-profil-ikoner-mdx" */),
  "component---src-pages-profil-illustrasjoner-mdx": () => import("./../../../src/pages/profil/illustrasjoner.mdx" /* webpackChunkName: "component---src-pages-profil-illustrasjoner-mdx" */),
  "component---src-pages-profil-logo-mdx": () => import("./../../../src/pages/profil/logo.mdx" /* webpackChunkName: "component---src-pages-profil-logo-mdx" */),
  "component---src-pages-profil-responsiv-styling-mdx": () => import("./../../../src/pages/profil/responsiv-styling.mdx" /* webpackChunkName: "component---src-pages-profil-responsiv-styling-mdx" */),
  "component---src-pages-profil-spacing-mdx": () => import("./../../../src/pages/profil/spacing.mdx" /* webpackChunkName: "component---src-pages-profil-spacing-mdx" */),
  "component---src-pages-profil-stil-og-tone-mdx": () => import("./../../../src/pages/profil/stil-og-tone.mdx" /* webpackChunkName: "component---src-pages-profil-stil-og-tone-mdx" */),
  "component---src-pages-profil-theming-mdx": () => import("./../../../src/pages/profil/theming.mdx" /* webpackChunkName: "component---src-pages-profil-theming-mdx" */),
  "component---src-pages-profil-typografi-mdx": () => import("./../../../src/pages/profil/typografi.mdx" /* webpackChunkName: "component---src-pages-profil-typografi-mdx" */),
  "component---src-pages-universell-utforming-mdx": () => import("./../../../src/pages/universell-utforming.mdx" /* webpackChunkName: "component---src-pages-universell-utforming-mdx" */)
}

